// core version + navigation, pagination modules:
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay, Lazy } from 'swiper/core';

/**
 * set and initialize slider modules
 */
class Slider {

  /**
   * create a new slider
   */
  constructor(selector, options = {}) {
    // configure Swiper to use modules
    SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

    this.sliderSelector = selector;

    // default options
    this.options = {
      loop: false,
      autoplay: false,
      speed: 650,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
    };

    Object.assign(this.options, options);

    this.init();
  }

  init() {
    this.sliderInstance = new Swiper(this.sliderSelector, this.options);
  }
}

export default Slider;
