// `sage preset` installed this file automatically.
// Running `sage preset` again could result in automatic deletion of this file.
// Because of this, we do not recommend editing this file.
import 'what-input'

import 'foundation-sites/js/entries/plugins/foundation.reveal'
import 'foundation-sites/js/entries/plugins/foundation.util.mediaQuery'
import 'foundation-sites/js/entries/plugins/foundation.offcanvas'
import 'foundation-sites/js/entries/plugins/foundation.dropdownMenu'
import 'foundation-sites/js/entries/plugins/foundation.accordionMenu'
import 'foundation-sites/js/entries/plugins/foundation.smoothScroll'

jQuery(document).ready($ => ($(document).foundation()));
