/**
 * observeModules Class
 */

class moduleObserve {
  constructor() {
    this.elements = document.getElementsByClassName('effect_on');
    this.observedClass = 'visible';

    if (this.elements.length) {
      this.moduleObserver();
    }
  }

  buildThresholdList(steps) {
    let thresholds = [];

    thresholds.push(0);
    for (let i = 1.0; i <= steps; i++) {
      let ratio = i / steps;
      thresholds.push(ratio);
    }

    return thresholds;
  }

  moduleObserver() {
    let intersectRatio = 0.2;
    if (window.matchMedia('(min-width: 768px)').matches) {
      intersectRatio = 0.4;
    }

    const thresholds = this.buildThresholdList(20);
    let options = { threshold: thresholds };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if(entry.isIntersecting) {
          const windowHeight = window.document.documentElement.clientHeight
          if (entry.intersectionRatio > intersectRatio || entry.intersectionRect.height > intersectRatio * windowHeight) {
            if (entry.target.getAttribute('data-animate')) {
              const animate = entry.target.getAttribute('data-animate');

              if (animate == 'text_only') {
                const subAnimate = entry.target.querySelector('.editor_inner');
                const animateData = subAnimate.getAttribute('data-animate');

                subAnimate.classList.add(animateData);
                subAnimate.classList.add(this.observedClass);
                observer.unobserve(entry.target);
              } else {
                entry.target.classList.add(animate);
                entry.target.classList.add(this.observedClass);
                observer.unobserve(entry.target);
              }
            }
          }
        }
      });
    }, options);

    Array.from(this.elements).forEach((item) => {
      observer.observe(item);
    });
  }
}

export default moduleObserve;
