import SliderController from '../modules/SliderController';
import ScrollController from '../util/ScrollController';
import Tabs from '../modules/Tabs';
import moduleObserve from '../modules/moduleObserve';
import lax from 'lax.js';
import initAutoReveal from '../modules/AutoReveal';

export default {
  init() {
    // JavaScript to be fired on all pages
    const scrollControll = new ScrollController();
    scrollControll.setEventListener();
    new SliderController();
    new Tabs();
    new moduleObserve();

    lax.init();

    // Add a driver that we use to control our animations
    lax.addDriver('scrollY', function () {
      return window.scrollY;
    });

    // Add animation bindings to elements
    lax.addElements('.js-parallax-top', {
      scrollY: {
        translateY: [
          [0, 'elOutY'],
          [0, 'elHeight/2'],
          {
            easing: 'easeInQuad',
          },
        ],
      },
    });

    lax.addElements('.js-parallax', {
      scrollY: {
        translateY: [
          ['elInY', 'elOutY'],
          ['-elHeight/5', 'elHeight/5'],
        ],
      },
    });

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    jQuery(document).ready(function () {
      const footer = document.getElementById('page-footer');
      const scrollButton = jQuery('a.buttonScroll');

      let observer;
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            scrollButton.addClass('fade-out');
          } else {
            scrollButton.removeClass('fade-out');
          }
        });
      });

      observer.observe(footer);
    });

    initAutoReveal();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
