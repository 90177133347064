export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    const topSwiper = document.getElementById('top-slider');
    if (topSwiper.swiper !== undefined) {
      topSwiper.swiper.once('slideChange', () => {
        // hide element on first slide change
        document.querySelector('h1').classList.add('fade-out');
      });
    }
    const burgerButton = document.getElementById('hamburger-button');
    burgerButton.addEventListener(
      'click',
      () => {
        // hide element on first slide change
        document.querySelector('h1').classList.add('fade-out');
      },
      { once: true }
    );
  },
};
