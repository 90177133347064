class AutoReveal {
  constructor() {
    // elements for reveal
    this.element = jQuery('.reveal-auto');
    this.closeEl = this.element.find('button.close-button');

    // fields from options
    this.cookieId = this.element.attr('data-cookie-id');
    this.latency = parseInt(this.element.attr('data-cookie-latency'));

    // Name of our cookie
    this.cookieName = 'wav_modal';
    this.revealSeen = false;

    this.maxAge = 28 * 24 * 60 * 60; //28 days later
  }

  setCookie() {
    if (
      this.element.attr('data-trace') === 'untracked' ||
      typeof this.cookieId == 'undefined'
    ) {
      return false;
    }

    const wavCookies = document.cookie.split('; ');

    // value of cookie
    let cookieValue = '';

    // iterate through all cookies, checking for cookieName
    for (var i = 0, len = wavCookies.length; i < len; i++) {
      if (wavCookies[i].indexOf(this.cookieName) == 0)
        cookieValue = wavCookies[i].split('=')[1];
    }

    let revealIds = cookieValue === '' ? [] : cookieValue.split(',');
    // if we found cookie with expected id we quit
    if (revealIds.indexOf(this.cookieId) !== -1) return true;

    // otherwise, set value for cookie
    revealIds.push(this.cookieId);
    const newValue = revealIds.join(',');
    const cookieString = `${this.cookieName}=${newValue};max-age=${this.maxAge};path=/`;
    document.cookie = cookieString;
    return false;
  }

  init() {
    this.revealSeen = this.setCookie();

    if (this.revealSeen) return;

    window.setTimeout(() => {
      this.element.fadeIn();
    }, this.latency);

    this.closeEl.get(0).addEventListener('click', () => {
      this.element.fadeOut();
      this.element.remove();
    });
  }
}

function initAutoReveal() {
  if (!jQuery('.reveal-auto').length) return;

  const reveal = new AutoReveal();
  reveal.init();
}

export default initAutoReveal;
