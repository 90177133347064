import Slider from '../util/Slider';

/**
 * control and create Slider instances
 */
class SliderController {

  /**
   * create a new slider
   */
  constructor() {
    // selectors
    this.topSliderId = 'top-slider';
    this.galleryClass = 'sm-gallery';

    if (document.querySelector(`#${this.topSliderId}`) !== null)
      this.initTopSlider();

    if (document.querySelectorAll(`.${this.galleryClass}`).length)
      this.initGallerySliders()
  }

  initTopSlider() {
    const options = {
      loop: true,
      autoplay: {
        delay: 3000,
        speed: 600,
      },
    };

    new Slider(`#${this.topSliderId}`, options);
  }

  initGallerySliders() {
    const options = {
      slidesPerView: 1,
      breakpoints: {
        600: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    };

    new Slider(`.${this.galleryClass}`, options);
  }
}

export default SliderController;
