class ScrollController {
  constructor() {
    this.lastKnownScrollPosition = 0;
    this.lastKnownOverflowScroll = 0;
    this.ticking = false;
    this.scrollElement = null;

    this.updateScrollPoints(window.scrollY);
  }

   updateScrollDirection(delta) {
     if (delta >= 0) {
       document.body.classList.add('scroll-down');
       document.body.classList.remove('scroll-up');
     } else {
       document.body.classList.remove('scroll-down');
       document.body.classList.add('scroll-up');
     }
   }

  updateScrollPoints(scrollPosition) {
    if (scrollPosition <= 10) {
      document.body.classList.add('wav-top');
    } else {
      document.body.classList.remove('wav-top');
    }
  }

  onScroll() {
    const delta = window.scrollY - this.lastKnownScrollPosition;
    this.lastKnownScrollPosition = window.scrollY;

    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.updateScrollDirection(delta);
        this.updateScrollPoints(this.lastKnownScrollPosition);
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  setEventListener() {
    document.addEventListener('scroll', () => this.onScroll());
  }
}

export default ScrollController;
