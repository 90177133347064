/**
 * tabs class
 */
class Tabs {
  constructor() {
    this.elements = document.getElementsByClassName('wav-tabs-item');
    this.activeClass = 'active';
    this.observedClass = 'visible';

    if (this.elements.length) {
      this.init();
      this.tabsObserver();
    }
  }

  /**
   * set up event listener to open/close tabs on click
   */
  init() {
    for (let i = 0; i < this.elements.length; i++) {
      this.elements[i].addEventListener('click', (event) => {
        const element = event.currentTarget;

        if (element.classList.contains(this.activeClass)) {
          element.classList.remove(this.activeClass);
          return;
        }
        // get children of parent element
        const tabsItems = element.parentNode.children;
        // remove active class from all childen (tabs titles)
        Array.from(tabsItems).forEach((item) => {
          item.classList.remove(this.activeClass);
        });
        // add active class to current tab
        element.classList.add(this.activeClass);

        // check visibility and scroll into view if necessary
        const rect = element.getBoundingClientRect();
        if (rect.top < 0) {
          // top bar is shown on scroll up which is what we do
          const topBarHeight = document
            .getElementById('topBarMenu')
            .getBoundingClientRect().height;
          const targetY = window.scrollY + rect.top - topBarHeight;
          window.scrollTo({ top: targetY, behavior: 'smooth' });
        }
      });
    }
  }

  /**
   * add a class to an element if it is in view for the first time
   */
  tabsObserver() {
    let options = { threshold: 1.0 };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(this.observedClass);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    Array.from(this.elements).forEach((item) => {
      observer.observe(item);
    });
  }
}

export default Tabs;
